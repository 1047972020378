import React from "react";
import '../CSS/copyright.css'

const Copyright =() =>{

    return (
        <>
            <div className="copyright">
                 <p>@Copyright 2022 | Made by Weeelb.com</p>
            </div>
        </>
    )
}

export default Copyright