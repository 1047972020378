import React from "react";
import SectionTitle from "../components/SectionTitle";

const CollectionPage =() =>{

    return (
        <>  
            <SectionTitle heading="Collection" subheading="some of my recent works"/>
            <div>
               Collection
            </div>
        </>
    )
}
export default CollectionPage