import React from "react";
import SectionTitle from "../components/SectionTitle";

const AboutPage =() =>{

    return (
        <>  
            <SectionTitle heading="About" subheading="some of my recent works"/>
            <div>
               About Page
            </div>
        </>
    )
}

export default AboutPage